import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SwaggerUI from 'swagger-ui-react';
import { Map as imMap } from 'immutable';
import get from 'lodash/get';
import has from 'lodash/has';
import { saveAs } from 'file-saver';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AuButton from '@au/core/lib/components/elements/AuButton'
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import PrivacyPolicy from '@au/core/lib/components/elements/PrivacyPolicy';
import CustomAuth from '../components/CustomAuth';
import { SWAGGER_PATH, UNSET_SCHEMA_REFS } from '../constants';
import browserHistory from '../history';
import slug from '../utils/slug';
import shared from '../shared';
import { entries as localEntries, responses as localResponses } from '../localSwaggerDocs';

import 'swagger-ui-react/swagger-ui.css';

import styles from '../css/components/swagger.module.scss';

const openApiResponses = {};

const secretMenu = {
  items() {
    try {
      return JSON.parse(window.localStorage.getItem('auSecretMenu')) || {};
    } catch (e) {
      return {};
    }
  },
  getItem(key) {
    return this.items()[key];
  },
  setItem(key, value) {
    const items = this.items();
    items[key] = value;
    window.localStorage.setItem('auSecretMenu', JSON.stringify(items));
  }
};
window.auSecretMenu = secretMenu;

/**
 * SwaggerUiWrap is used to avoid unnecessary re-renders of SwaggerUI
 * @extends React
 */
class SwaggerUiWrap extends React.PureComponent {
  static propTypes = {
    requestInterceptor: T.func.isRequired,
    swaggerRef: T.string.isRequired
  }

  unsetSchemaRefs(moddedSpec, path, refs) {
    const { intl } = this.props;

    for (let schema of Object.values(get(moddedSpec, path))) {
      if (schema.deprecated || !schema.properties) {
        continue;
      }
      for (let propertyKey of Object.keys(schema.properties)) {
        for (let name of refs) {
          const refPath = `#/${path.replace('.', '/')}/${name}`;
          if (schema.properties[propertyKey].$ref === refPath) {
            schema.properties[propertyKey] = {
              type: 'object',
              description: intl.formatMessage(
                { id: 'au.swagger.seeDefinition' },
                { model: `[${name}](#model-${name})` }
              )
            };
          }
        }
      }
    }
  }

  render() {
    const { spec, requestInterceptor } = this.props;
    const moddedSpec = { ...spec };

    // point host to dev-portal's apiDomain v2
    if ((moddedSpec.swagger || '').startsWith('2.')) {
      moddedSpec.host = shared.config.apiDomain;
    }

    // point host to dev-portal's apiDomain v3
    if ((moddedSpec.openapi || '').startsWith('3.') && moddedSpec.servers && Array.isArray(moddedSpec.servers)) {
      for (let server of moddedSpec.servers) {
        const pathPrefix = server.url.split('/').slice(3).join('/');
        server.url = `https://${shared.config.apiDomain}/${pathPrefix}`;
      }
    }

    // remove `securityDefinitions` since this is already handled
    if (moddedSpec.securityDefinitions) {
      delete moddedSpec.securityDefinitions;
    }

    // remove `schemes` since we are only using https
    if (moddedSpec.schemes) {
      delete moddedSpec.schemes;
    }

    const unsetRefs = UNSET_SCHEMA_REFS[this.props.swaggerRef];
    // replace selected $ref properties with a link to a designated model
    if (unsetRefs) {
      for (let [path, refs] of Object.entries(unsetRefs)) {
        if (has(moddedSpec, path)) {
          this.unsetSchemaRefs(moddedSpec, path, refs);
        }
      }
    }

    return (
      <div className={styles.container}>
        <SwaggerUI
          spec={moddedSpec}
          requestInterceptor={requestInterceptor}
        />
      </div>
    );
  }
}

const SwaggerUiWrapIntl = injectIntl(SwaggerUiWrap);

class Swagger extends AuComponent {
  static propTypes = {
    swaggerEntries: IPT.map,
    swaggerRef: T.string,
    selection: T.string
  }

  static defaultProps = {
    swaggerEntries: imMap()
  }

  state = {
    customAuthToken: null,
    isLoading: false
  }

  componentDidMount() {
    window.addEventListener('click', this.handleOnClick, false);
  }

  componentDidUpdate() {
    const { selection } = this.props;

    // using setTimeout to avoid errors about setting state during rendering
    setTimeout(() => this.loadSwagger(selection), 0);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleOnClick);
  }

  handleOnClick(e) {
    const { target } = e;
    const hash = target.getAttribute('href') || '';
    // anchors only
    if (target.tagName === 'A' && hash.length > 1 && hash.indexOf('#') === 0) {
      const el = document.getElementById(hash.slice(1));
      if (el) {
        // expand model section if button is present
        const btn = el.querySelector('.model-box > .pointer');
        if (btn) {
          btn.click();
        }
        el.scrollIntoView();
      }
    }
  }

  buildOptions() {
    const options = [];
    let { swaggerEntries } = this.props;

    for (let [title, url] of swaggerEntries.entries()) {
      options.push({ val: url, displayString: title });
    }
    return options;
  }

  selectOption = this.selectOption.bind(this);
  selectOption(serviceUrl) {
    const { swaggerEntries } = this.props;
    const title = swaggerEntries.findKey(v => v === serviceUrl);

    if (title) {
      browserHistory.push(`${SWAGGER_PATH}/` + slug(title));
    }
    this.loadSwagger(serviceUrl);
  }

  loadSwagger = this.loadSwagger.bind(this);
  loadSwagger(serviceUrl) {
    if (localResponses[serviceUrl] && !openApiResponses[serviceUrl]) {
      this.setState({ isLoading: true }, async () => {
        const responseOrGet = localResponses[serviceUrl];
        if (typeof responseOrGet === 'function') {
          openApiResponses[serviceUrl] = (await responseOrGet(shared.transporter)).data; // assumes content-type is json
        }
        else {
          openApiResponses[serviceUrl] = responseOrGet;
        }
        this.setState({ isLoading: false });
      });
      return;
    }

    if (serviceUrl && !this.state.isLoading && openApiResponses[serviceUrl] !== null) {
      if (!openApiResponses[serviceUrl]) {
        this.setState({ isLoading: true }, () =>
          shared.transporter.get(serviceUrl)
            .then(
              resp => {
                try {
                  const swagger = typeof resp.data === 'string' ? JSON.parse(resp.data) : resp.data;
                  openApiResponses[serviceUrl] = swagger;
                } catch (e) {
                  // FIXME: remove this once https://www.pivotaltracker.com/story/show/166627037 is fixed
                  AuAnalytics.trackException(
                    `Failed to parse Swagger Doc for: ${serviceUrl} for: "${resp.data}"`
                  );
                  openApiResponses[serviceUrl] = null;
                }
              },
              resp => {
                AuAnalytics.trackException(
                  `Failed to load Swagger Doc for: ${serviceUrl} because of error: ${resp}`
                );
                openApiResponses[serviceUrl] = null;
              }
            )
            .then(() => this.setState({ isLoading: false }))
        );
      }
    }
  }

  downloadSwagger = this.downloadSwagger.bind(this);
  downloadSwagger(selection) {
    const file = new Blob([JSON.stringify(openApiResponses[selection])], { type: "text/plain;charset=utf-8"})
    saveAs(file, selection + '.json');
  }

  requestInterceptor = this.requestInterceptor.bind(this);
  requestInterceptor(request) {
    const { customAuthToken } = this.state;
    const { accessToken } = customAuthToken ? customAuthToken : shared;
    request.headers.authorization = 'Bearer ' + accessToken;
    return request;
  }

  onNewToken = this.onNewToken.bind(this);
  onNewToken(token) {
    // a timeout is needed to keep the access token alive because Swagger UI has
    // a bug generating the cURL command if the requestInterceptor returns a
    // promise.
    // https://github.com/swagger-api/swagger-ui/issues/4778
    if (this.customAuthTokenTimer) {
      clearInterval(this.customAuthTokenTimer);
    }
    if (token) {
      this.customAuthTokenTimer = setInterval(() => token.getLiveAccessToken().catch(r => r), 10000);
    }
    this.setState({ customAuthToken: token });
  }

  renderSwaggerUi(selection) {
    return (
      <SwaggerUiWrapIntl
        spec={openApiResponses[selection]}
        swaggerRef={this.props.swaggerRef}
        requestInterceptor={this.requestInterceptor}
      />
    );
  }

  renderErrorMessage() {
    return (
      <AutoIntl displayId="au.swagger.failedToLoad" tag="h3" />
    );
  }

  render() {
    const { isLoading } = this.state;
    const options = this.buildOptions();
    const { selection } = this.props;
    const error = !isLoading && openApiResponses[selection] === null;
    const ready = !isLoading && !error && selection && openApiResponses[selection];

    return (
      <div className={styles.wrapper}>
        <div className={styles.selection_bar}>
          { selection &&
            <div className={styles.left_container}>
              <AutoIntl displayId={"au.service"} tag="h7" className={styles.service_text} />
              <AuDropDown
                className={styles.dropdown}
                selection={selection}
                selectOption={this.selectOption}
                options={options}
              />
            </div>
          }
          <div className={styles.right_container}>
            <AuButton
              size="medium"
              type="tertiary"
              displayId="au.downloadSwagger"
              className={styles.download_button}
              onClick={() => this.downloadSwagger(selection)}
            />
            <CustomAuth onNewToken={this.onNewToken} />
          </div>
        </div>
        <LoadingIndicator className={styles.loader} display={isLoading} />
        { error && this.renderErrorMessage() }
        { ready && this.renderSwaggerUi(selection) }
        <div className={styles.flex} />
        <PrivacyPolicy />
      </div>
    );
  }
}

export default connect(
  ({ app: state }, { match }) => {
    const { swaggerRef } = match.params;
    const swaggerEntries = state.get('swaggerEntries', imMap()).mergeDeep(imMap(localEntries));
    let optionsArray = [];
    if (swaggerEntries.size) {
      for (let [title, url] of swaggerEntries.entries()) {
        optionsArray.push({ val: url, displayString: title });
      }

      optionsArray.sort((a, b) => {
        if (a.displayString < b.displayString) return -1;
        if (a.displayString > b.displayString) return 1;
        return 0;
      });
    }

    let selection;

    if (swaggerEntries.size) {
      if (swaggerRef) {
        selection = swaggerEntries.find((entry, key) => slug(key) === swaggerRef);
      }
      else {
        let firstKey = optionsArray[0].displayString;

        if (firstKey) {
          browserHistory.push(`${SWAGGER_PATH}/` + slug(firstKey));
        }
      }
    }

    return swaggerEntries.size ? { swaggerEntries, swaggerRef, selection } : { };
  }
)(Swagger);

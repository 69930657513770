import 'whatwg-fetch';

import { fetchBlob, fetchJson } from '@au/core/lib/network/fetch';
import { LOCAL_DOCS_FOLDER } from '../constants';

// should expose the same methods as s3.js
// NOTE: _get is a method used only in s3.js and does not have to be added

const fetchConfig = {
  method: 'GET',
  headers: {
    'Accept': 'text/markdown; charset=UTF-8'
  }
};

class LocalDocs {
  constructor(path) {
    this._path = path;
  }

  get path() {
    return this._path;
  }

  _pathToBlob() {
    return fetchBlob(this.path)
      .then(blob => {
        if (blob.type === 'text/html') {
          // check if webpack devserver returned index.html instead of the file
          return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = () => {
              const content = reader.result;
              if (content.startsWith('<!DOCTYPE html>')) {
                rej(`${this.path} does not exists`);
              } else {
                res(blob);
              }
            };
            reader.readAsText(blob);
          });
        }
        return blob;
      });
  }

  blob() {
    return this._pathToBlob(this.path);
  }

  blobUrl() {
    return this.blob()
      .then(blob => URL.createObjectURL(blob));
  }

  string() {
    return fetch(`/${LOCAL_DOCS_FOLDER}/${this.path}`, fetchConfig).then(resp => {
      if (resp.ok && resp.status === 200) {
        return resp.text().then(data => { return { payload: data }; });
      }
      throw new Error(`failed to retrieve document (${this.path})`);
    });
  }

  json() {
    return fetchJson(`/${LOCAL_DOCS_FOLDER}/${this.path}`).then(data => { return { payload: data }; });
  }
}

export default LocalDocs;

import React from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AlertMessage from '@au/core/lib/components/objects/AlertMessage';
import GlobalNavigation from '@au/core/lib/components/objects/GlobalNavigation';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import { getTmcLink } from '@au/core/lib/utils/tmcLinks';
import { TMC_METRICS_LINK } from '@au/core/lib/constants';
// import SecurityPage from '@au/core/lib/components/objects/SecurityPage';

import {
  SWAGGER_PATH,
  DEV_PREVIEW_PATH,
  DOC_APP_PATH_KEY,
  PERMALINKS_DOC_KEY,
  tmcLinkTypes,
  TMC_LOOKUP_PATH,
  DATA_SAMPLER_PATH,
  ENV_CNP0, ENV_CNP1,
  AUTONOMIC_AND_FORD_DOMAINS
} from '../constants';
import { getToc } from '../utils/selectors';
import shared from '../shared';
import slug from '../utils/slug';

import CurrentLocale from '../utils/reactIntl';

import DevPreview from '../components/DevPreview';
import Search, { navlink as searchNavlink } from './Search';
import Swagger from './Swagger';
import TocRouter from './TocRouter';
import TMCLookupRouter from "../components/tmcLookup/TMCLookupRouter";
import DataSamplerRouter from "../components/dataSampler/DataSamplerRouter";
import Security from '../components/SecurityPage';

import styles from '../css/components/nav.module.scss';

export function isAutonomicOrFordUser() {
  return AUTONOMIC_AND_FORD_DOMAINS.includes(shared.config.emailDomain);
}

const Nav = ({ navLinks, zoneLinks, permaRedirects, accountName, accountId, showDataSampler, dataSamplerLoading }) =>
  <GlobalNavigation
    navLinkClassName={styles.link}
    activeNavLinkClassName={styles.active}
    navLinks={navLinks}
    logoLink={<Link to="/" data-tid="Nav-logoLink" />}
    accountId={accountId}
    accountName={accountName}
  >
    <AlertMessage />
    <CurrentLocale
        /*
          This component is used only to add the ability to import
          "utils/reactIntl" which exposes formatMessage function
          from react-intl package.
        */
      />
    {/* wait until loading is completed instead of flashing 404 page */}
    {!dataSamplerLoading && (
      <Switch>
        {
          permaRedirects.map(props =>
            <Route path={props.from} key={props.from} render={({ location, match }) => {
              let { to } = props;
              if (match.params.all) {
                // for wildcard permalink append the remaining URL
                to += '/' + match.params.all + location.hash;
              }
              to += location.hash;
              AuAnalytics.trackEvent({
                category: 'Document',
                action: 'permalink-redirect',
                label: `${location.pathname} -> ${to}`
              });
              return <Redirect from={props.from} to={to} />;
            }} />
          )
        }
        <Route path={`${SWAGGER_PATH}/:swaggerRef`}                               component={Swagger} />
        <Route path={SWAGGER_PATH}                                                component={Swagger} exact />
        <Route path={'/security'}                                                 component={Security}/>
        {isAutonomicOrFordUser() && <Route path={TMC_LOOKUP_PATH}                 component={TMCLookupRouter} />}
        {showDataSampler && <Route path={DATA_SAMPLER_PATH}                       component={DataSamplerRouter} />}
        {shared.config.environment === 'dev' && <Route path={DEV_PREVIEW_PATH}    component={DevPreview} />}
        <Route path={'/:zoneName'}                                                component={TocRouter} />
        {zoneLinks.length && <Redirect to={zoneLinks[0].destination} />}
      </Switch>
    )}
  </GlobalNavigation>;

const getTmcMetricsLinks = () => {
  const TMC_LINK_NA = getTmcLink(TMC_METRICS_LINK, shared.config.baseDomain, shared.config.partition);
  const TMC_LINK_CNP0 = getTmcLink(TMC_METRICS_LINK, shared.config.baseDomain, ENV_CNP0);
  const TMC_LINK_CNP1 = getTmcLink(TMC_METRICS_LINK, shared.config.baseDomain, ENV_CNP1);    
  const tmcMetricsLinks = [
    {labelId: "au.env.label"},
    {labelId: "au.env.na", destination: TMC_LINK_NA?.destination},
    {labelId: "au.env.cnp0", destination: TMC_LINK_CNP0?.destination},
    {labelId: "au.env.cnp1", destination: TMC_LINK_CNP1?.destination},
  ];
  return tmcMetricsLinks;
};

const ConnectedNav = connect(
  ({ app: state }) => {
    const toc = getToc(state);
    const accountName = state.get('accountName');
    const accountId = state.get('accountId');
    const showDataSampler = state.get('dataSampler')?.canAccess;
    const dataSamplerLoading = state.get('dataSamplerLoading');
    const tmcMetricsLinks = getTmcMetricsLinks();

    let navLinks = [
      {
        destination: SWAGGER_PATH,
        className: styles.swagger,
        labelId: 'au.navigation.link.swagger'
      },
    ];

    if (isAutonomicOrFordUser()) {
      navLinks.push({
        destination: TMC_LOOKUP_PATH,
        labelId: 'au.tmcLookup.header'
      });
    }

    if (showDataSampler) {
      navLinks.push({
        destination: DATA_SAMPLER_PATH,
        labelId: 'au.dataSampler.header'
      });  
    }
    
    if (shared.config.environment === 'dev') {
      navLinks.push({
        destination: DEV_PREVIEW_PATH,
        labelId: 'au.navigation.link.devPreview'
      });
    }

    const zoneLinks = [];
    const permaRedirects = [];
    if (toc) {
      for (let zoneName of toc.toc.keys()) {
        zoneLinks.push({
          destination: '/' + slug(zoneName),
          tcl: 'Nav-zoneTab',
          label: zoneName
        });
      }

      navLinks = [...zoneLinks, ...navLinks];

      if (shared.config.cloudSearchDomain) {
        navLinks.push({
          label: '',
          component: <Search />,
          className: searchNavlink
        });
      }

      toc.eachEntry(entry => {
        let permalinks = entry.get(PERMALINKS_DOC_KEY);
        if (!permalinks) {
          return;
        }
        for (let link of permalinks) {
          // update wildcard permalinks ending in '/*' (these are only
          // directories)
          if (link.endsWith('/*')) {
            link = link.slice(0, -1) + ':all(.*)';
          }
          permaRedirects.push({
            from: link,
            to: entry.get(DOC_APP_PATH_KEY)
          });
        }
      });
    }

    navLinks.push({
      labelId: 'au.tmcLinks',
      className: styles.docs,
      navLinks: tmcLinkTypes.map(linkType => {
        const link = getTmcLink(linkType);
        if (link) {
          const item = {labelId: link.displayId};
          if (linkType !== TMC_METRICS_LINK) {
            item.destination = link.destination;
          } else {
            item.navLinks = tmcMetricsLinks;
          }
          return item;
        }
        return link;
      }).filter(link => link)
    });

    return { navLinks, zoneLinks, permaRedirects, accountName, accountId, showDataSampler, dataSamplerLoading };
  }
)(Nav);

export default ConnectedNav;
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { isMsie } from './../utils/userAgent';
import styles from './../css/components/browser_modal.module.scss';

class BrowserModal extends PureComponent {
  state = {
    closed: this.withinOneMonth() || !isMsie
  }

  closeModal = this.closeModal.bind(this);
  closeModal() {
    window.localStorage.setItem('auBrowserModalLastClosed', new Date().toISOString());
    this.setState({ closed: true });
  }

  withinOneMonth() {
    try {
      let lastDate = window.localStorage.getItem('auBrowserModalLastClosed');
      // x milliseconds * (1 second  / 1000 milliseconds) * (1 minute / 60 seconds) * (1 hour / 60 minutes) * (1 day / 24 hours) * (1 month / 30 days) = x months
      return (new Date() - new Date(lastDate))/1000/60/60/24/30 < 1;
    } catch (e) {
      window.localStorage.removeItem('auBrowserModalLastClosed');
      return false;
    }
  }

  browsers() {
    const modernBrowsers = {
      microsoftEdge: {
        url: 'https://www.microsoft.com/windows/microsoft-edge'
      },
      mozillaFirefox: {
        url: 'https://www.mozilla.org/firefox/'
      },
      googleChrome: {
        url: 'https://www.google.com/chrome/'
      }
    };

    return Object.keys(modernBrowsers).map(browser => {
      return (
        <div className={styles.browser} key={browser}>
          <div className={cn(styles.browserimage, styles[browser])} alt={`${browser} icon`}/>
          <a href={modernBrowsers[browser].url} target="_blank" rel="noopener noreferrer"> <FormattedMessage id={`au.ie11.${browser}`}/> </a>
        </div>
      );
    });
  }

  render() {
    if (this.state.closed) {
      return false;
    }

    return (
      <div className={styles.overlay}>
        <div className = {styles.content}>
          <div className={styles.exit} onClick={this.closeModal} data-cid="BrowserModal-exit" />
          <div className={styles.title}>
            <FormattedMessage id='au.ie11.title'/>
          </div>
          <div className={styles.body}>
            <FormattedMessage id='au.ie11.body'/>
          </div>
          <div className={styles.browsers}>
            {this.browsers()}
          </div>
        </div>
      </div>
    );
  }
}

export default BrowserModal;

import coreLocales from '@au/core/lib/locales';

const defaultEnglish = {
  'au': 'Hello, {name}',
  'au.noop': ' ',
  'au.copyUri': "Copy URI",
  'au.uri': "URI: {uri}",
  'au.tmcLookup.uri': "URI :",
  'au.uriUnavailable': "URI Unavailable",
  'au.tmcLinks': "TMC Links",
  'au.tmcMetrics': "TMC Metrics",
  'au.feedback': "Feedback",
  'au.helpCenter': "Help Center",
  'au.console': "Console",
  'au.loadingText': 'Updating...',
  'au.downloadSwagger': 'Download Swagger UI',
  'au.search': 'Search',
  'au.service': 'Service',
  'au.services.BQS.name': 'Behavior Query Service',
  'au.services.DEPLOYMENT.name': 'Deployment Service',
  'au.services.DEPLOYMENTV2.name': 'Deployment Service V2',
  'au.services.DEPLOYMENTV3.name': 'Deployment Service V3',
  'au.services.FEED.name': 'Feed Admin Service',
  'au.services.GEOFENCE.name': 'Geofence Service',
  'au.services.GROUP.name': 'Group Service',
  'au.services.MQS.name': 'Metrics Query Service',
  'au.services.OEM.name': 'OEM Command Service',
  'au.services.RESOURCES.name': 'Resources Service',
  'au.services.RESOURCESV2.name': 'Resources Service V2',
  'au.services.RIDEHAILING.name': 'Ride-Hailing Service',
  'au.services.RIDEHAILINGV2BETA.name': 'Ride-Hailing Service V2 Beta',
  'au.services.VEHICLE.name': 'Vehicle Service',
  'au.services.VSS.name': 'Vehicle State Sreaming Service',
  'au.home.title': 'Developer',
  'au.swagger.failedToLoad': 'Failed to load',
  'au.swagger.setCredentials': 'Use My Client',
  'au.swagger.clearCredentials': 'Clear Client Credentials',
  'au.swagger.seeDefinition': 'See {model} definition',
  'au.navigation.link.referenceDocs': 'Reference Docs',
  'au.navigation.link.tutorials': 'Tutorials',
  'au.navigation.link.swagger': 'Swagger UI',
  'au.navigation.link.devPreview': 'MD Previewer',
  'au.navigation.link.ftcpConverter': 'FTCP Converter',
  'au.serviceStatus': 'Service Status',
  'au.docs.view': 'View',
  'au.account': 'Account',
  'au.logOut': 'Log Out',
  'au.login': 'Login',
  'au.clientId': 'Client ID',
  'au.clientSecret': 'Client Secret',
  'au.loginWithConfidentialClient': 'Login with Confidential Client',
  'au.doc.lastModified': 'Last modified',
  'au.doc.lastModifiedFormat': 'MMM D YYYY h:mm a zz',
  'au.doc.downloadAs': 'Download as',
  'au.doc.downloadAs.markdown': 'Markdown',
  'au.doc.swagger': 'Swagger',
  'au.subscribe': 'Subscribe to TMC News',
  'au.subscribe.subheader': 'Receive an email notification whenever TMC service updates',
  'au.subscribe.confirmation': 'Subscribe Via Email',
  'au.subscribe.message': 'Autonomic is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. By clicking below, you agree that we may proceed your information in accordance with these terms.',
  'au.subscribe.accept.communication': 'I accept to receive communication from Autonomic',
  'au.subscribe.success': 'Your subscription was successfully submitted!',
  'au.subscribe.success.message': 'Thank you for the subscription! Now you will receive an email notification whenever TMC service updates.',
  'au.subscribe.success.signoff': '- Autonomic team -',
  'au.notFound.pageNotFound': 'Page Not Found',
  'au.notFound.couldNotFindPage': 'Sorry, we could not find the page you requested.',
  'au.notFound.goBack': 'Go Back',
  'au.markdown.copy': 'Copy',
  'au.nav.menu': 'Menu',
  'au.nav.close': 'Close',
  'au.filedrop.msg': 'Drop a Markdown file to preview',
  'au.filedrop.caveat': 'Images Not Supported!!!',
  'au.ie11.title': 'Update your browser for the best experience',
  'au.ie11.body': 'Some features might not work correctly with the browser you’re using. To get the most of our services, we recommend that you update to the current version of one of these browsers:',
  'au.ie11.microsoftEdge': 'Microsoft Edge',
  'au.ie11.mozillaFirefox': 'Mozilla Firefox',
  'au.ie11.googleChrome': 'Google Chrome',
  'au.ie11.safari': 'Safari',
  'au.tmcLookup.header': 'Lookup',
  'au.tmcLookup.sourceInfo': 'Source Information',
  'au.tmcLookup.alertNotice': 'TMC - FTCP conversion rules are applicable up to date of {date}.',
  'au.tmcLookup.ftcp.lookupHeader': 'Lookup for FTCP Data',
  'au.tmcLookup.ftcp.lookupSearch': 'Alert, CAN signal, or Signal bundle',
  'au.tmcLookup.version': 'Version',
  'au.tmcLookup.version.latest': 'Latest',
  'au.tmcLookup.search': 'Search',
  'au.tmcLookup.type': 'Type',
  'au.tmcLookup.tmc.lookupHeader': 'Lookup for TMC Data',
  'au.tmcLookup.tmc.lookupSearch': 'Event or Signal',
  'au.tmcLookup.searchPlaceholder': 'Type',
  'au.tmcLookup.noResults': 'No results found, please try another value',
  'au.tmcLookup.ftcpEvent' : 'Type: Alert ({count})',
  'au.tmcLookup.ftcpSignal' : 'Type: Signal ({count})',
  'au.tmcLookup.ftcpBundle' : 'Type: Signal Bundle ({count})',
  'au.tmcLookup.wellKnownEvents' : 'Type: Event ({count})',
  'au.tmcLookup.wellKnownSignals' : 'Type: Signal ({count})',
  'au.tmcLookup.wellKnownIndicators': 'Type: Indicator ({count})',
  'au.tmcLookup.wellKnownConfigurations': 'Type: Configurations ({count})',
  'au.tmcLookup.indicatorNotAvailable': 'The Well Known Signals INDICATOR_LIGHT and CONFIGURATION are converted from multiple FTCP elements. Please contact {email} for more information.',
  'au.tmcLookup.help': 'Not sure where to start about TMC Telemetry. {linkText}',
  'au.ftcpLookup.header': 'FTCP Lookup',
  'au.ftcpLookup.simulate': 'Simulate In Feed',
  'au.ftcpLookup.searchResult': 'Search Result',
  'au.ftcpLookup.alertFound': '{alertName} is converted to {tmcEventName} on TMC. Please review the table below for the FTCP signals that are converted.',
  'au.ftcpLookup.alertFoundNotConverted': '{alertName} is currently not converted into a TMC event. The table below contains FTCP signals that are converted (if any).',
  'au.ftcpLookup.alertError': 'No alert found for Alert="{name}" and version="{version}".',
  'au.ftcpLookup.noSignals': 'This alert does not contain any TMC signals.',
  'au.ftcpLookup.searchPlaceholder': 'Type',
  'au.signalMappingTable.messageName': 'Message Name',
  'au.signalMappingTable.canSignal': 'CAN Signal',
  'au.signalMappingTable.tmcSignal': 'TMC Signal',
  'au.signalMappingTable.defaultValue': 'Default Value',
  'au.signalMappingTable.description': 'Ford Description',
  'au.signalMappingTable.notSupportedOnTMC': 'Currently, not supported on TMC.',
  'au.signalMappingTable.ftcpElements': 'FTCP Elements',
  'au.signalMappingTable.triggerConditions': 'Trigger Conditions',
  'au.signalMappingTable.requiredSignals': 'Required TMC Signals',
  'au.signalMappingTable.tmcSignals': 'TMC Signals',
  'au.signalMappingTable.ftcpSignal': 'FTCP Signal',
  'au.signalMappingTable.ftcpVersions': 'FTCP Versions',
  'au.ftcpLookup.popupText': 'The Transportation Mobility Cloud (TMC) provides different types of messages via different services. The most common types are: Telemetry (e.g., well-known signals and well-known events)',
  'au.ftcpLookup.loadingIndicator': 'Loading... Please wait...',
  'au.ftcpLookup.resultsCount': '{alertName}, in the way it is currently configured, generates {eventCount, plural, one {# event} other {# events}} and {metricCount, plural, one {# metric} other {# metrics}} on the TMC.',
  'au.resultsDrawer.jsonView': 'JSON View',
  'au.resultsDrawer.ftcpLookup.loadingIndicator': 'Simulating in Feed... Please wait...',
  'au.resultsDrawer.uri': 'URI',
  'au.resultsDrawer.ftcpMessage': 'FTCP Message',
  'au.resultsDrawer.events': 'Events',
  'au.resultsDrawer.metrics': 'Metrics',
  'au.resultsDrawer.dataSample': 'Data Sample',
  'au.collapsibleTable.collapseAll': 'Collapse All',
  'au.collapsibleTable.expandAll': 'Expand All',
  'au.canSignalFtcp.header': 'CAN Signal FTCP',
  'au.canSignalFtcp.addCanSignals': 'Add All CAN Signals',
  'au.canSignalFtcp.simulateInFeed': 'Simulate In Feed',
  'au.canSignalFtcp.message': 'Generate a representation of {event} in Feed using the table below. You can either use the default values provided, or select the values you would like to see in Feed.',
  'au.canSignalFtcp.ftcpMessage': 'FTCP Message',
  'au.canSignalFtcp.canSignal': 'CAN Signal',
  'au.canSignalFtcp.defaultValue': 'Default Value',
  'au.canSignalFtcp.representationInFeed': 'Representation In Feed',
  'au.jsonBox.copyClipboard': 'Copy to clipboard',
  'au.jsonBox.copied': 'Copied',
  'au.jsonBox.nothingToDisplay': 'Nothing to display',
  'au.ftcpSignalInformation.header': 'Ftcp Signal Information',
  'au.ftcpSignalInformation.signalConverted': '{signalName} is converted to {tmcSignalName} on the TMC.',
  'au.ftcpSignalInformation.signalFoundNotConverted': '{signalName} is currently not converted into a TMC Signal.',
  'au.ftcpSignalInformation.signalConvertedConfiguration': '{signalName} is converted to the {tmcSignalName} Well Known Configuration which is included in the Well Known Signal {type}.',
  'au.ftcpSignalInformation.signalConvertedIndicator': '{signalName} is converted to the Well Known Indicator {tmcSignalName} contained in the Well Known Signal {type}.',
  'au.ftcpSignalInformation.tmcUnit': '(TMC unit: {tmcUnit})',
  'au.ftcpSignalInformation.signalError': 'No signal found for canSignal="{name}" and version="{version}".',
  'au.ftcpSignalInformation.signalDescription': 'Description :',
  'au.ftcpSignalInformation.tableHeader.NUMERIC': 'Numeric Value',
  'au.ftcpSignalInformation.tableHeader.SED': 'Enum Value Mapping',
  'au.ftcpSignalInformation.tableHeader.NON_CAN_ENUM': 'NonCan Enum Value Mapping',
  'au.ftcpSignalInformation.tableHeader.NON_CAN_NUMERIC': 'NonCan Numeric Value Mapping',
  'au.ftcpSignalInformation.tableHeader.RANGE': 'FTCP Range Mapping',
  'au.ftcpSignalInformation.ftcpValue': 'FTCP Value',
  'au.ftcpSignalInformation.enum': 'Enum',
  'au.ftcpSignalInformation.tmc': 'TMC',
  'au.ftcpSignalInformation.viewSignal': 'View Signal In Feed',
  'au.ftcpSignalInformation.version': 'Version :',
  "au.ftcpSignalInformation.unit": "Unit : ",
  'au.ftcpSignalInformation.intValueHeader': 'Int Value',
  'au.ftcpSignalInformation.tmcLowerBound': 'TMC Lower Bound',
  'au.ftcpSignalInformation.tmcUpperBound': 'TMC Upper Bound',
  'au.ftcpSignalInformation.rangeMapping': 'FTCP Range Mapping',
  "au.ftcpSignalInformation.minValue": "Min Value",
  "au.ftcpSignalInformation.maxValue": "Max Value",
  "au.ftcpSignalInformation.valueOffset": "Value Offset",
  "au.ftcpSignalInformation.resolution": "Resolution",
  "au.ftcpSignalInformation.unknownDataType": "Data type not implemented",
  "au.ftcpSignalInformation.noMatch": "No matching value found",
  'au.ftcp.searchResult': 'Search Result',
  'au.ftcp.dataType': 'Data type :',
  'au.ftcp.dataType.NUMERIC': 'NUMERIC',
  'au.ftcp.dataType.SED': 'ENUM (SED)',
  'au.ftcp.dataType.NON_CAN_ENUM': 'NonCan Enum',
  'au.ftcp.dataType.NON_CAN_NUMERIC': 'NonCan Numeric',
  'au.ftcp.dataType.RANGE': 'ENUM (SED)',
  'au.ftcp.dataType.ENUM_VALUE': 'Enum',
  'au.ftcp.dataType.DOUBLE_VALUE': 'Double Value',
  'au.ftcp.dataType.undefined': 'Undefined',
  'au.signalBundle.searchResult': 'Please review the table below for the FTCP signals that are converted within <b>{signalBundle}</b>:',
  'au.signalBundle.header': 'Signal Bundle Information',
  'au.signalBundle.tableHeader': 'Signal Bundle Mapping',
  'au.tmcEvent.eventConverted': '{eventName} is converted from the following FTCP elements:',
  'au.tmcEvent.eventNotConverted': '{eventName} is not converted from any FTCP elements.',
  'au.tmcEvent.eventError': 'No event found for event="{name}".',
  'au.tmcEvent.mappingError': 'Unable to successfully map event="{name}".',
  'au.tmcEvent.notConverted': 'Currently not converted from FTCP.',
  'au.tmcEvent.header': 'TMC Event',
  'au.tmcSignal.header': 'TMC Signal',
  'au.tmcIndicator.header': 'TMC Indicator',
  'au.tmcConfiguration.header': 'TMC Configuration',
  'au.tmcEvent.conditionsTableHeader': 'Trigger Conditions & Required Signals',
  'au.tmcEvent.conditionsTableSubheader': 'Below are the conditions and required signals for the FTCP Alerts',
  'au.tmcEvent.signalMappingHeader': 'TMC Signal Mapping',
  'au.tmcEvent.noSignals': 'This alert does not contain any TMC signals.',
  'au.tmcEvent.noMetrics': 'No metrics',
  'au.tmcEvent.noSignalsRequired': 'No signal required',
  'au.tmcEvent.containedSignals': 'Please review the table below for signals it contains.',
  'au.tmcSignal.searchResultConverted': '{signalName} is converted from the following FTCP Signals:',
  'au.tmcSignal.searchResultNotConverted': '{signalName} is not converted from any FTCP Signals.',
  'au.tmcSignal.searchResultConfiguration': 'The Well Known Configuration {signalName} is included in the Well Known Signal {type} and is converted from the following FTCP signals:',
  'au.tmcSignal.searchResultIndicator': '{signalName} is a Well Known Indicator contained in the Well Known Signal {type} and is converted from the following FTCP signals:',
  'au.tmcSignal.signalError': 'No signal found for signal="{name}".',
  'au.tmcSignal.signalNotFound': 'No signal found for signal="{name}".',
  'au.tmcSignal.enumValues' : 'Enum Values',
  'au.tmcSignal.tagName' : 'Tag Name',
  'au.tmcSignal.tagValue' : 'Tag Value',
  'au.tmcSignal.noAssociatedTags': 'There are no tags associated with {signal}.',
  'au.tmcSignal.noAssociatedValues': 'There are no associated values.',
  'au.tmcSignal.enumsTableTitle': 'Enums',
  'au.tmcSignal.tagsTableTitle': 'Tags',
  'au.filter.deviceType': 'Device Type',
  'au.filter.dataType': 'Data Type',
  'au.filter.sampleStatus': 'Sample Status',
  'au.dataSampler.header': 'Data Sampler',
  'au.dataSampler.info_blurb': 'Data Sampler gives insight into what Events, Signals, Indicators, Configurations, and State Transitions are supported by each Device Type. It also has anonymized samples of real data, which can be used to understand the data structure and when creating test cases.',
  'au.dataSampler.entities.header': 'All Data',
  'au.dataSampler.entities.viewDetails': 'View Details',
  'au.dataSampler.entities.search': 'Search',
  'au.dataSampler.sampleStatus.captured': 'Captured',
  'au.dataSampler.sampleStatus.notCaptured': 'Not Captured',
  'au.dataSampler.dataType.wellKnown': 'Well Known Event',
  'au.dataSampler.dataType.custom': 'Custom Event',
  'au.dataSampler.events.viewDetails': 'View Details',
  'au.dataSampler.events.search': 'Search',
  'au.dataSampler.feedback': 'Feedback',
  'au.dataSampler.alertNotice': 'Data Sampler started collecting data since the date of {date}.',
  'au.dataSampler.filterByHeader': 'Filter by',
  'au.dataSampler.lookupHeader': 'Lookup',
  'au.dataSampler.deviceTypeSelect': 'Device Type',
  'au.dataSampler.entityTypeSelect': 'Data Type',
  'au.dataSampler.filterByViewButton': 'View',
  'au.datasampler.select.placeholder': 'Choose',
  'au.datasampler.search.placeholder': 'Type',
  'au.dataSampler.lookup.${entity.value}': '',
  'au.dataSampler.lookup.label': 'Event, signal, indicator, configuration, or state machine',
  'au.dataSampler.lookup.events': 'Type: Event ({count})',
  'au.dataSampler.lookup.signals' : 'Type: Signal ({count})',
  'au.dataSampler.lookup.indicators': 'Type: Indicator ({count})',
  'au.dataSampler.lookup.configurations': 'Type: Configuration ({count})',
  'au.dataSampler.lookup.stateMachines': 'Type: State Machine ({count})',
  'au.dataSampler.lookup.noResults': 'No results found, please try another value',
  'au.dataSampler.help': "I'd like to learn about TMC data types. {linkText}",
  'au.dataSampler.eventDetails.sampleButton': 'Sample',
  'au.dataSampler.events.deviceType': 'Device Type',
  'au.dataSampler.events.triggerConditions': 'Trigger Conditions',
  'au.dataSampler.events.triggerConditions.description': 'Description :',
  'au.dataSampler.events.triggerConditions.uri': 'URI :',
  'au.dataSampler.events.triggerConditions.tableHeader': 'Trigger Conditions & Required Signals',
  'au.dataSampler.events.requiredSignals': 'Required TMC Signals',
  'au.dataSampler.events.conditionsTableSubheader': 'Below are the conditions and required signals for this event.',
  'au.dataSampler.events.additionalSignals.header': 'Additional Signals',
  'au.dataSampler.events.additionalSignal.noSignals': 'This event does not contain any additional signals.',
  'au.dataSampler.events.additionalSignal.rowHeader' : 'Name',
  'au.dataSampler.events.tags.header': 'Tags',
  'au.dataSampler.events.tags.noTags': 'There are no tags associated with {event}.',
  'au.dataSampler.signals.header.custom': 'Custom Signal',
  'au.dataSampler.signals.header.wellKnown': 'Well Known Signal',
  'au.dataSampler.signals.tags.header': 'Tags',
  'au.dataSampler.signals.tags.noTags': 'There are no tags associated with {signal}.',
  'au.dataSampler.signals.enum.header': 'Enums',
  'au.dataSampler.signals.enums.enum': 'Enum',
  'au.dataSampler.signals.enums.values': 'Values',
  'au.dataSampler.signals.enums.noSignals': 'This signal does not contain any enums.',
  'au.dataSampler.configuration.header': 'Configuration',
  'au.dataSampler.indicator.header': 'Indicator',
  'au.dataSampler.noSample': 'No Sample',
  'au.dataSampler.sampleButton': 'Sample',
  'au.dataSampler.entityResult.description': 'Description :',
  'au.dataSampler.entityResult.uri': 'URI :',
  'au.dataSampler.entityResult.dataType': 'Data Type :',
  'au.dataSampler.entityResult.unit': 'Unit :',
  'au.dataSampler.deviceTypeSelector.deviceType': 'Device Type',
  'au.loadingIndicator': 'Loading... Please wait...',
  'au.dataSampler.error': 'Nothing to display. Please change filters or search input.',
  'au.dataSampler.stateMachine.header': 'State Machine',
  'au.dataSampler.stateMachine.stateTransitions.header': 'State Transitions',
  'au.dataSampler.stateTransitions.state.rowHeader': 'State',
  'au.dataSampler.stateTransitions.state.copy': 'Copy URI',
  'au.apiRequest.error': "Error processing the request",
  'au.dataSampler.entityDetails.entityBreadCrumb.wellKnownEvent': "Well Known Event",
  'au.dataSampler.entityDetails.entityBreadCrumb.customEvent': "Custom Event",
  'au.dataSampler.entityDetails.entityBreadCrumb.wellKnownSignal': "Well Known Signal",
  'au.dataSampler.entityDetails.entityBreadCrumb.customSignal': "Custom Signal",
  'au.dataSampler.entityDetails.entityBreadCrumb.indicator': "Indicator",
  'au.dataSampler.entityDetails.entityBreadCrumb.configuration': "Configuration",
  'au.dataSampler.entityDetails.entityBreadCrumb.stateMachine': "State Machine",
};

const defaultMandarin= {
  'au': '您好, {name}',
  'au.loadingText': '更新中...',
  'au.home.title': 'TMC 开发者门户',
  'au.home.subTitle': '欢迎来到车联云平台! 浏览我们的开发者文档，包括 API 参考、教程和代码示例。',
  'au.swagger.failedToLoad': '加载失败',
  'au.navigation.link.referenceDocs': '参考文档',
  'au.navigation.link.tutorials': '教程',
  'au.navigation.link.devPreview': 'Markdown 预览工具',
  'au.docs.view': '查看',
  'au.account': '账号',
  'au.logOut': '登出',
  'au.doc.lastModified': '最近更新日期:',
  'au.doc.lastModifiedFormat': 'MMM D YYYY h:mm a zz',
  'au.doc.downloadAs': '下载当前文档为',
  'au.notFound.pageNotFound': '页面无法找到',
  'au.notFound.couldNotFindPage': '抱歉，无法找到您请求的页面。',
  'au.notFound.goBack': '返回',
  'au.nav.menu': '菜单',
  'au.nav.close': '关闭',
  'au.filedrop.msg': '拖放 Markdown 文件进行预览',
  'au.filedrop.caveat': '图片不支持！'
};

export default {
  en: Object.assign({}, coreLocales.en, defaultEnglish),
  zh: Object.assign({}, defaultEnglish, (coreLocales.zh || {}), defaultMandarin)
};

import React, { Component } from 'react';
import * as T from 'prop-types';

import TMC from '@autonomic/browser-sdk';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AuButton from '@au/core/lib/components/elements/AuButton';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';
import { ENTER_KEY } from '@au/core/lib/constants';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import { NOOP } from '../constants';
import shared from '../shared';

import styles from '../css/components/custom_auth.module.scss';

// NOTE: This is a WIP and is hidden behind a feature flag and is not intended
//       for regular customers and is purely a POC.

// TODO: localize
// TODO: add option for token only
// TODO: add username and password
export default class CustomAuth extends Component {
  static propTypes = {
    onNewToken: T.func,
  }

  static defaultProps = {
    onNewToken: NOOP
  }

  state = { authConfig: null, isLoading: false, hasError: false, showDialog: false };
  inputText = { clientId: '', clientSecret: '' };

  stopMaintaining() {
    if (this.state.authConfig) {
      this.state.authConfig.token.maintain = false; // eslint-disable-line react/no-direct-mutation-state
    }
  }

  componentWillUnmount() {
    this.stopMaintaining();
  }

  onAuth = this.onAuth.bind(this);
  onAuth(authConfig) {
    this.stopMaintaining();
    this.setState({ authConfig, showDialog: false });
    this.props.onNewToken(authConfig.token);
  }

  onRemove = this.onRemove.bind(this);
  onRemove() {
    this.stopMaintaining();
    this.setState({ authConfig: null});
    this.props.onNewToken(null);
  }

  auth = this.auth.bind(this);
  auth() {
    this.setState({ isLoading: true }, () =>
      TMC.auth({...this.inputText, base: shared.base }, false)
        .then(
          this.onAuth,
          () => this.setState({ hasError: true }))
        .finally(
          () => this.setState({ isLoading: false }))
    );
  }

  onKeyDown = this.onKeyDown.bind(this);
  onKeyDown(e) {
    if (e.key === ENTER_KEY) { // enter
      this.auth();
    }
  }

  onKeyUp = this.onKeyUp.bind(this);
  onKeyUp(e) {
    const { name } = e.target;
    this.inputText[name] = e.target.value.trim();
  }

  onFirstInputMount = this.onFirstInputMount.bind(this);
  onFirstInputMount(el) {
    setTimeout(() => el && el.focus(), 1);
  }

  showDialog() {
    return (
      <ConfirmationDialog
        onCancel={() => this.setState({ showDialog: false })}
        onConfirm={this.auth}
        bodyClassName={styles.body}
        className={styles.outer_container}
        showCloseIcon={false}
        titleId={"au.loginWithConfidentialClient"}
        headerClassName={styles.au_header}
        confirmDisplayId={"au.login"}
        buttonsClassName={styles.buttons}
      >
        <div className={styles.dialog}>
          <div className={styles.input_container}>
            <AutoIntl displayId={"au.clientId"} className={styles.text} />
            <AuInput
              type="text"
              tabIndex={900}
              onKeyDown={this.onKeyDown}
              onKeyUp={this.onKeyUp}
              name="clientId"
              onMount={this.onFirstInputMount}
              className={styles.input}
            />
          </div>
          <div className={styles.secret_input_container}>
            <AutoIntl displayId={"au.clientSecret"} className={styles.text} />
            <AuInput
              type="text"
              tabIndex={901}
              onKeyDown={this.onKeyDown}
              onKeyUp={this.onKeyUp}
              name="clientSecret"
              className={styles.input}
            />
          </div>
        </div>
      </ConfirmationDialog>
    );
  }

  render() {
    const { authConfig, showDialog } = this.state;
    // TODO: handle isLoading
    // TODO: handle hasError

    let clientId;
    if (authConfig) {
      clientId = authConfig.clientId;
    }

    return (
      <div className={styles.container}>
        { !authConfig && <AuButton
          size="medium"
          type="secondary"
          displayId="au.swagger.setCredentials"
          onClick={() => this.setState({ showDialog: true })} /> }
        { authConfig && <AuButton
          size="medium"
          disabled={!authConfig}
          displayId="au.swagger.clearCredentials"
          onClick={this.onRemove} />}
        <div className={styles.using_client}>{clientId && 'Using ' + clientId}</div>
        { showDialog && this.showDialog() }
      </div>
    );
  }
}